<template>
  <div class="pagenotfound-view">
    <div class="container-wrapped-1">
      <p>Oops! This page doesn't exist.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageNotFound',
  components: {}
});
</script>

<style scoped lang="scss">
.pagenotfound-view {
}
</style>
